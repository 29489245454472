<template>
  <div class="box">
    <div class="title">我的注册</div>
    <template v-if="dataList.length>0">
      <ul class="ul">
        <li v-for="(item,index) in dataList" :key="index" :class="'li-Bg'+item.status">
          <div class="type">{{item.infoCategoryName}}</div>
          <div class="status">
            <span class="status1" v-if="item.status==1">待审核</span>
            <span class="status2" v-else-if="item.status==2">审核通过</span>
            <span class="status3" v-else-if="item.status==3">审核不通过</span>
            <span v-else></span>
          </div>
          <div class="name">王锐</div>
          <div class="time">{{toDate(item.createTime,'yyyy-MM-dd')}}</div>
        </li>
      </ul>
      <div style="clear: both;"></div>
      <el-pagination class="page-bar" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
    </template>
    <div v-else class="noData">暂无数据</div>
  </div>
</template>

<script>
import {baseInfoInfoPersonnelPage} from "../../../api/template";

export default {
  name: "myRegistration.vue",
  data(){
    return{
      params:{
        currentPage:1,
        pageSize:10,
        organizeId:window.localStorage.getItem("domainTid")
      },
      total:0,
      dataList:[]
    }
  },
  created() {
    this.getBaseInfoInfoPersonnelPage()
  },
  methods:{
    getBaseInfoInfoPersonnelPage(){
      baseInfoInfoPersonnelPage(this.params).then(res=>{
        if(res.code == 200){
          this.dataList = res.data.list
          this.total = res.data.pagination.total
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getBaseInfoInfoPersonnelPage();
    },
    handleCurrentChange(val) {
      this.params.currentPage = val;
      this.getBaseInfoInfoPersonnelPage();
    },
  }
}
</script>

<style scoped lang="scss">
.box {
  position: relative;
  width: 1020px;
  background: #fff;
  margin: 20px auto;
  padding: 20px 90px;
  min-height: 560px;
  .title {
    font-size: 28px;
    padding-bottom: 20px;
    color: #333;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #d2d2d2;
  }
  .ul{
    position: relative;
    margin-left: -30px;
    min-height: 560px;
    li{
      position: relative;
      float: left;
      width: 320px;
      height: 140px;
      margin-left: 30px;
      margin-top: 20px;
      font-size: 20px;
      color: #333;
      //background: url("../../../assets/image/registerBg.png");
      //background: #eee;
      border-radius: 5px;
      font-weight: bold;
      //border: 1px solid #333;
      //box-shadow: 0px 2px 6px 0px;
      .type{
        position: absolute;
        left: 30px;
        top: 20px;
        font-size: 24px;
      }
      .status{
        position: absolute;
        right: 30px;
        top: 20px;
        color: #fff;
        font-size: 24px;
        //.status1{
        //  color: #409eff;
        //}
        //.status2{
        //  color: #67c23a;
        //}
        //.status3{
        //  color: #f56c6c;
        //}
      }
      .name{
        position: absolute;
        bottom: 55px;
        left: 30px;
      }
      .time{
        position: absolute;
        left: 30px;
        bottom: 20px;
      }
    }
    .li-Bg1{
      background: url("../../../assets/image/registerBg1.png");
      background-size: 100% 100%;
    }
    .li-Bg2{
      background: url("../../../assets/image/registerBg2.png");
      background-size: 100% 100%;
    }
    .li-Bg3{
      background: url("../../../assets/image/registerBg3.png");
      background-size: 100% 100%;
    }
  }
  .noData{
    position: absolute;
    left: 50%;
    top: 280px;
    font-size: 28px;
    color: #333;
  }
}
.page-bar{
  margin-bottom: 0;
  margin-top: 10px;
}
</style>