// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/image/registerBg1.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/image/registerBg2.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/image/registerBg3.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".box[data-v-f0af0d80]{position:relative;width:1020px;background:#fff;margin:20px auto;padding:20px 90px;min-height:560px}.box .title[data-v-f0af0d80]{font-size:28px;padding-bottom:20px;color:#333;font-weight:700;text-align:center;border-bottom:1px solid #d2d2d2}.box .ul[data-v-f0af0d80]{position:relative;margin-left:-30px;min-height:560px}.box .ul li[data-v-f0af0d80]{position:relative;float:left;width:320px;height:140px;margin-left:30px;margin-top:20px;font-size:20px;color:#333;border-radius:5px;font-weight:700}.box .ul li .type[data-v-f0af0d80]{left:30px}.box .ul li .status[data-v-f0af0d80],.box .ul li .type[data-v-f0af0d80]{position:absolute;top:20px;font-size:24px}.box .ul li .status[data-v-f0af0d80]{right:30px;color:#fff}.box .ul li .name[data-v-f0af0d80]{position:absolute;bottom:55px;left:30px}.box .ul li .time[data-v-f0af0d80]{position:absolute;left:30px;bottom:20px}.box .ul .li-Bg1[data-v-f0af0d80]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.box .ul .li-Bg2[data-v-f0af0d80]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-size:100% 100%}.box .ul .li-Bg3[data-v-f0af0d80]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-size:100% 100%}.box .noData[data-v-f0af0d80]{position:absolute;left:50%;top:280px;font-size:28px;color:#333}.page-bar[data-v-f0af0d80]{margin-bottom:0;margin-top:10px}", ""]);
// Exports
module.exports = exports;
